import { provideHttpClient, withInterceptors, withRequestsMadeViaParent } from '@angular/common/http';
import { Provider } from '@angular/core';

import { SharedDataAccessProviders } from '@portal/shared/data-access';
import { provideLocalizationDateAdapter, tenantIdInterceptor, userAuthInterceptor } from '@portal/shared/utils';

export const authenticatedDomain: Provider = [
    provideLocalizationDateAdapter(),
    provideHttpClient(withRequestsMadeViaParent(), withInterceptors([tenantIdInterceptor, userAuthInterceptor])),
    SharedDataAccessProviders,
];
