import { Type } from '@angular/core';
import { Route } from '@angular/router';

import { authenticatedDomain } from '@portal/shared/domain';
import { AdminAuthNavigatorService, UserAuthNavigatorService, UserAuthService } from '@portal/shared/utils';

import {
    ApplicationFeatures,
    AUTH_MANAGER,
    AUTH_NAVIGATOR,
    authCanActivateGuard,
    getFeatureCanMatchGuard,
    nonProductionCanMatchGuard,
    TldUtils,
} from '@portal/core/utils';

import { TenantNexusAuthNavigatorService, TenantNexusAuthService } from '@portal/tenant-nexus/utils';
import { userDomain } from '@portal/user/domain';

import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

const publicTenantRoutes: Route[] = [
    {
        path: '',
        providers: [
            {
                provide: AUTH_MANAGER,
                useExisting: UserAuthService,
            },
            {
                provide: AUTH_NAVIGATOR,
                useClass: UserAuthNavigatorService,
            },
            userDomain,
        ],
        loadComponent: async () => (await import('@portal/shared/routes')).PublicLayoutComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'user',
            },
            {
                path: 'user',
                canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.USER_APPLICATION)],
                loadChildren: async () => (await import('@portal/user/routes')).routes,
            },
        ],
    },
] satisfies Route[];

const publicTenantAdminRoutes: Route[] = [
    {
        path: '',
        providers: [
            {
                provide: AUTH_MANAGER,
                useExisting: UserAuthService,
            },
            {
                provide: AUTH_NAVIGATOR,
                useClass: AdminAuthNavigatorService,
            },
            userDomain,
        ],
        loadComponent: async () => (await import('@portal/shared/routes')).PublicLayoutComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'admin',
            },
            {
                path: 'admin',
                canMatch: [nonProductionCanMatchGuard],
                loadChildren: async () => (await import('@portal/tenant-admin/routes')).routes,
            },
        ],
    },
] satisfies Route[];

const authenticatedTenantRoutes: Route[] = [
    {
        path: '',
        providers: [
            {
                provide: AUTH_MANAGER,
                useExisting: UserAuthService,
            },
            {
                provide: AUTH_NAVIGATOR,
                useClass: UserAuthNavigatorService,
            },
            authenticatedDomain,
        ],
        canActivate: [authCanActivateGuard],
        loadComponent: async () => (await import('@portal/shared/routes')).AuthenticatedLayoutComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'contacts',
            },
            {
                path: 'contacts',
                canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.CONTACTS_APPLICATION)],
                loadChildren: async () => (await import('@portal/contacts/routes')).routes,
            },
            {
                path: 'calendar',
                canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.CALENDAR_APPLICATION)],
                loadChildren: async () => (await import('@portal/calendar/routes')).routes,
            },
            {
                path: 'case-files',
                canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.CASE_FILE_APPLICATION)],
                loadChildren: async () => (await import('@portal/case-files/routes')).routes,
            },
            {
                path: 'accounting',
                canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.ACCOUNTING_APPLICATION)],
                loadChildren: async () => (await import('@portal/accounting/routes')).routes,
            },
            {
                path: 'admin-console',
                canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.ADMIN_CONSOLE_APPLICATION)],
                loadChildren: async () => (await import('@portal/admin-console/routes')).routes,
            },
            {
                path: 'team',
                canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.TEAM_APPLICATION)],
                loadChildren: async () => (await import('@portal/team/routes')).routes,
            },
        ],
    },
] satisfies Route[];

const tenantNexusRoutes: Route[] = [
    {
        path: '',
        canMatch: [nonProductionCanMatchGuard],
        providers: [
            {
                provide: AUTH_MANAGER,
                useExisting: TenantNexusAuthService,
            },
            {
                provide: AUTH_NAVIGATOR,
                useClass: TenantNexusAuthNavigatorService,
            },
        ],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'tenant-nexus',
            },
            {
                path: 'tenant-nexus',
                loadChildren: async () => (await import('@portal/tenant-nexus/routes')).routes,
            },
        ],
    },
] satisfies Route[];

const devRoute: Route = {
    path: 'dev',
    canMatch: [nonProductionCanMatchGuard],
    loadComponent: async () => (await import('@portal/shared/routes')).SandboxComponent as Type<unknown>,
};

export function getAppRoutes(isDevMode: boolean): Route[] {
    return [
        ...(isDevMode ? [devRoute] : []),
        ...(isDevMode && TldUtils.hasDomain() && !TldUtils.hasSubdomain() ? tenantNexusRoutes : []),
        ...(TldUtils.hasSubdomain() ? [...authenticatedTenantRoutes, ...publicTenantRoutes, ...publicTenantAdminRoutes] : []),
        {
            path: '403',
            component: ForbiddenPageComponent,
        },
        {
            path: '404',
            component: NotFoundPageComponent,
        },
        {
            path: '**',
            redirectTo: '404',
        },
    ] satisfies Route[];
}
