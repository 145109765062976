import { provideHttpClient, withInterceptors, withRequestsMadeViaParent } from '@angular/common/http';
import { Provider } from '@angular/core';

import { tenantIdInterceptor, userAuthInterceptor } from '@portal/shared/utils';

import { UserDataAccessProviders } from '@portal/user/data-access';

export const userDomain: Provider = [
    provideHttpClient(withRequestsMadeViaParent(), withInterceptors([tenantIdInterceptor, userAuthInterceptor])),
    UserDataAccessProviders,
];
